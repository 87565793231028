import Axios from "axios";
import { applyAuthInterceptor } from "./authInterceptor";
import axiosMocker from "./axiosMocker";
import { ICryptoBtcWithdraw } from "./types";

interface Iconfig {
  baseURL?: string;
  headers?: any;
  timeout?: number;
}

// config axios
let axiosConfig: Iconfig = {
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const externalAxios = Axios.create(axiosConfig);
export const myAxios = Axios.create({
  ...axiosConfig,
  ...(import.meta.env.DEV
    ? { headers: { "h-origin": import.meta.env.REACT_APP_GWFE_URL } }
    : {}),
});
applyAuthInterceptor(myAxios);

// configure mocks
if (import.meta.env.REACT_APP_USE_AXIOS_MOCK === "true") {
  axiosMocker(myAxios);
  axiosMocker(externalAxios);
}

// Add a request interceptor
myAxios.interceptors.request.use(
  (request) => request,
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
myAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

// api calls
export const callPymtType = (id: string, mode: string) =>
  myAxios.get(`/${mode}/${id}`);

export const callCryptoBtcWithdraw = (data: ICryptoBtcWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);
export const callNetellerWithdraw = (data: INetellerWithdraw, id: string) =>
  myAxios.post(`/withdraw/${id}`, data);

export const callCryptoBtcPostDeposit = (id: string, data: { kind: string }) =>
  myAxios.post(`/deposit/${id}`, data);

export const callCryptoBtcGetDeposit = (id: string) =>
  myAxios.get(`/deposit/${id}`);

export const callCryptoBtcCheckInvoice = (id: string) =>
  myAxios.get(`/btcInvoice/${id}`);

// txID is required on the BE to detect brand
export const callGetSettings = (txID: string) =>
  myAxios.get("/settings/all/public", { params: { id: txID } });
