import React, { useEffect, useState, Fragment, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";

import BubbleAlert from "Components/BubbleAlert";

import { callCryptoBtcCheckInvoice } from "../../API/API";
import { getError, getPaymentOptionsKindToNameMap } from "../../utils";
import MyCountdown from "./MyCountdown";
import CopyToClipBoard from "./CopyToClipBoard";
import CryptoNetworkWarningMessage from "./CryptoNetworkMessage";
import { networkData, ICryptoNetwork, getNetworkMapped } from "./consts";
import { usePublicSettingsQuery } from "API/hooks";

interface IProps {
  id: string;
  redirectUrl: string;
}

interface ITxData {
  status: string;
  kind: string;
  address: string | null;
  expires_at: number;
  amount: {
    invoiced: { amount: string; currency?: string };
    paid: { amount: string };
  };
  lightning?: { payment_hash: string; payment_request: string };
}

const parseRippleAddress = (
  addressAndDestinationTag: string,
  returnDestinationTag: boolean = false
): string => {
  const [xrpAddress, xrpDestination] = addressAndDestinationTag.split(":");
  if (returnDestinationTag) {
    return xrpDestination;
  }
  return xrpAddress;
};

const timeoutCallCryptoBtcCheck = async (
  id: string,
  setStatus: (arg: { status: string; message: any }) => void,
  setTxData: (data: SetStateAction<ITxData>) => void
) => {
  try {
    const res = await callCryptoBtcCheckInvoice(id);
    setTxData(res.data);
    setStatus({ status: "success", message: "" });
    if (res.data.status === "pending") {
      return;
    }
    setTimeout(
      async () => timeoutCallCryptoBtcCheck(id, setStatus, setTxData),
      10000
    );
  } catch (err) {
    const getErr = getError(err);
    setStatus({ status: "error", message: getErr.message });
    if (getErr.retry) {
      setTimeout(() => {
        setStatus({ status: "", message: "" });
      }, 1000);
    }
    setTimeout(
      async () => timeoutCallCryptoBtcCheck(id, setStatus, setTxData),
      1000
    );
  }
};

const getSanitisedAddress = (txData: ITxData) => {
  if (txData.kind === "BTC_LIGHTNING") {
    return (txData.lightning && txData.lightning.payment_request) || "";
  } else if (txData.kind === "XRP") {
    return parseRippleAddress(txData.address || "");
  }
  return txData.address || "";
};

const DepositInfo = ({ id, redirectUrl }: IProps) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({ status: "", message: "" });
  const [txData, setTxData] = useState<ITxData>({
    status: "",
    kind: "",
    address: "",
    expires_at: 0,
    amount: { invoiced: { amount: "", currency: "" }, paid: { amount: "" } },
  });
  const [cryptoNetwork, setCryptoNetwork] = useState<ICryptoNetwork | null>(
    null
  );

  const { data: appSettings } = usePublicSettingsQuery();

  useEffect(() => {
    const callAPI = async () => {
      try {
        const res = await callCryptoBtcCheckInvoice(id);
        setStatus({ status: "success", message: "" });
        setTxData(res.data);
      } catch (err) {
        const getErr = getError(err);
        if (getErr.retry) {
          setTimeout(() => {
            setStatus({ status: "", message: "" });
          }, 1000);
        }
      }
    };
    callAPI();
  }, [id]);

  useEffect(() => {
    setTimeout(
      async () => timeoutCallCryptoBtcCheck(id, setStatus, setTxData),
      1000
    );
  }, [id]);

  // redirection logic
  useEffect(() => {
    if (txData.status === "pending" || txData.status === "complete") {
      setTimeout(() => {
        if (import.meta.env.PROD) {
          window.location.href = redirectUrl;
        } else {
          console.warn(
            `If this was NODE_ENV production we would redirect now to ${redirectUrl}`
          );
        }
      }, 3000);
    }
  }, [txData, redirectUrl]);

  // CryptoNetworkWarning logic
  useEffect(() => {
    if (!txData.kind) return;

    const depositNetworkMapped = getNetworkMapped(txData.kind);

    setCryptoNetwork(networkData?.[depositNetworkMapped]);
  }, [txData]);

  const returnAmount = () => {
    const invAmt = txData.amount.invoiced.amount;
    const paidAmt =
      (txData &&
        txData.amount &&
        txData.amount.paid &&
        txData.amount.paid.amount) ||
      "";
    const amt =
      Number(invAmt.replace(/,/g, "")) - Number(paidAmt.replace(/,/g, ""));

    return String(amt);
  };

  const sanitisedAddress: string = getSanitisedAddress(txData);

  const returnQRstring = () => {
    switch (txData.kind) {
      case "BTC":
        return `bitcoin:${sanitisedAddress}?amount=${returnAmount()}`;
      case "LTC":
        return `litecoin:${sanitisedAddress}?amount=${returnAmount()}`;
      default:
        return sanitisedAddress;
    }
  };

  const kindToSymbolNameMapping = getPaymentOptionsKindToNameMap(appSettings);
  const fallbackCurrencyKind = txData.kind;
  const currencyKind = txData.amount.invoiced?.currency ?? fallbackCurrencyKind;
  const currency = kindToSymbolNameMapping?.[currencyKind] ?? currencyKind;

  if (status.status === "success") {
    return (
      <div>
        <div className="mb-3">
          <h4>
            {t("deposit-info-title", {
              currency,
            })}
          </h4>

          {txData.status === "incomplete" && (
            <div className="alert alert-warning">
              {t("deposit-info-send-remaining-funds")}
            </div>
          )}

          {(txData.status === "pending" || txData.status === "complete") && (
            <div className="alert alert-success">
              {t("deposit-info-waiting-for-confirmation")}
              <br />
              <br />
              {t("deposit-info-redirect-to-dashboard")}
            </div>
          )}

          {txData.status !== "pending" && (
            <Fragment>
              <p className="text-muted">
                {t("deposit-info-complete-transaction-before-expiration")}
              </p>

              <MyCountdown countdownTo={txData.expires_at} />
            </Fragment>
          )}
        </div>

        {txData.status !== "pending" && (
          <Fragment>
            <div className="mb-2">
              <QRCode value={returnQRstring()} />
            </div>
            <div className="mb-3">
              <small className="text-muted">
                {currency} {t("address")}:{" "}
              </small>
              <CopyToClipBoard
                value={sanitisedAddress}
                text={t("copy-address")}
              />

              <div
                className="p-3 bg-light mb-2"
                style={{
                  border:
                    txData.status !== "pending" ? "1px solid #D79528" : "",
                }}
              >
                {sanitisedAddress}
              </div>
              {txData.status !== "pending" && (
                <BubbleAlert icon="fas fa-exclamation-circle">
                  <div className="font-weight-bold">
                    {t("deposit-info-address-used-once", {
                      currency,
                    })}
                  </div>
                  <div className="mt-2">
                    <p>{t("deposit-info-address-reuse")}</p>{" "}
                    <span className="font-weight-bold">
                      {t("deposit-info-correct-address")}
                    </span>
                  </div>
                  {cryptoNetwork && (
                    <CryptoNetworkWarningMessage
                      cryptoNetwork={cryptoNetwork}
                    />
                  )}
                </BubbleAlert>
              )}
            </div>
            {txData.kind === "XRP" && (
              <div className="mb-3">
                <small className="text-muted">{t("XRP-destination-tag")}</small>
                <div className="p-3 bg-light mb-2">
                  {parseRippleAddress(txData.address || "", true)}
                </div>
                <CopyToClipBoard
                  value={parseRippleAddress(txData.address || "", true)}
                  text={t("copy-destination-tag")}
                />
              </div>
            )}
            <div className="mt-1">
              <small className="text-muted">
                {t("send-amount", {
                  currency,
                })}
              </small>
              <h5 className="mb-2">{returnAmount()}</h5>

              <CopyToClipBoard value={returnAmount()} text={t("copy-amount")} />
            </div>
          </Fragment>
        )}
      </div>
    );
  }

  return (
    <div>
      <h4 className="text-muted mb-0">
        <i className="fas fa-spinner fa-spin" /> {t("loading-text")}
      </h4>
      {status.status === "error" && (
        <div className="alert alert-danger mb-3">{status.message}</div>
      )}
    </div>
  );
};

export default DepositInfo;
