import React, { FC, useEffect, useState } from "react";
import { callPymtType } from "../API/API";
import CryptoBtcDeposit from "../Forms/CryptoBtc/CryptoBtcDeposit";
import CryptoBtcWithdrawForm from "../Forms/CryptoBtc/CryptoBtcWithdrawForm";

import {
  ProviderTypes,
  ProvidersID,
  Providers,
} from "@ra/utils-v2/dist/gateway";
import { RouteComponentProps } from "react-router-dom";
import Instacoins from "Forms/Instacoins";
import ErrorBox from "../Components/ErrorBox";
import { getError, useTxId } from "../utils/index";
import CardWrap from "../Components/CardWrap";
import { useTranslation } from "react-i18next";
import { RenderComponentByStatus } from "../utils/RenderComponentByStatus";

interface IProps extends RouteComponentProps {
  mode: string;
  admin?: boolean;
}

interface IpymtInfo {
  status: string;
  type: string;
  currency: number;
  amount: number;
  meta: any;
  redirect: string;
  cryptoCurrencies: string[];
  message?: string;
  cardTypes?: [] | { id: string; name: string }[];
  providerSpecificMetaData?: any;
}

const TransactionPage: FC<IProps> = (props) => {
  const { mode } = props;

  const { t } = useTranslation();
  const txID = useTxId();

  const defaultError = txID
    ? { message: "", retry: false }
    : { message: t("invalid-transaction-id"), retry: false };

  const [error, setError] = useState(defaultError);

  const [pymtInfo, setPymtInfo] = useState<Partial<IpymtInfo>>({});
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    callPymtType(txID, mode)
      .then((res) => {
        setPymtInfo(res.data);
        setStatus("success");
      })
      .catch((err) => {
        setError(getError(err));
        setStatus("error");
      });
  }, [txID, mode]);

  const renderTxSwitch = () => {
    const {
      type = "",
      currency = 0,
      amount = 0,
      meta,
      redirect = "",
      cryptoCurrencies = [],
      status: pymtStatus = "",
    } = pymtInfo;
    const pymtType = ProvidersID[type];
    const compType = ProviderTypes[pymtType];

    if (props.mode === "deposit") {
      switch (compType) {
        case "CUSTOM":
          switch (type) {
            case Providers.INSTACOINS:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            case Providers.INSTACOINS_WIRE:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            case Providers.INSTACOINS_ZELLE:
              return (
                <CardWrap mode={props.mode} classes="col">
                  <Instacoins />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="deposit" />
              );
          }
        case "BTC":
          switch (type) {
            case Providers.CRYPTO_BTC:
              return (
                <CardWrap mode={props.mode}>
                  <CryptoBtcDeposit
                    cryptoCurrencies={cryptoCurrencies}
                    redirectUrl={redirect}
                    id={txID}
                    status={pymtStatus}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="deposit" />
              );
          }
        default:
          return <ErrorBox text={t("unknown-payment-type")} mode="deposit" />;
      }
    } else if (props.mode === "withdraw") {
      switch (compType) {
        case "BTC":
          switch (type) {
            case Providers.CRYPTO_BTC:
              return (
                <CardWrap mode={props.mode}>
                  <CryptoBtcWithdrawForm
                    id={txID}
                    currency={currency}
                    amount={amount}
                    cryptoKind={meta.cryptoKind}
                  />
                </CardWrap>
              );
            default:
              return (
                <ErrorBox text={t("unknown-payment-type")} mode="withdraw" />
              );
          }
        default:
          return <ErrorBox text={t("unknown-payment-type")} mode="withdraw" />;
      }
    } else {
      return <ErrorBox text={t("unknown-payment-type")} />;
    }
  };

  return RenderComponentByStatus(status, error, props.mode, renderTxSwitch);
};

export default TransactionPage;
